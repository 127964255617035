import React from "react"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import SplitPanel from "../components/pages/SplitPanel"
import SplitCard from "../components/pages/SplitCard"
import CenterText from "../components/pages/CenterText"
import Panels from "../components/pages/Panels"

// Data
import content from "../pages/static-pages/about.yaml"

class Index extends React.Component {
  render() {
    const breadcrumbs = [
      {
        label: content.title,
        path: "/about/",
        active: true,
      },
    ]

    return (
      <Layout>
        <SEO
          title={content.title}
          description={content.seo_description}
          image={content.seo_image}
        />

        <Breadcrumbs items={breadcrumbs} absolute={true} contrast={true} />

        {/* Feature */}
        <div
          className="feature-banner uk-section uk-background-cover uk-light"
          data-src={content.feature.image.url}
          uk-img=""
        >
          <div className="uk-container uk-margin-large-top">
            <div className="uk-width-4-5 uk-width-3-5@s uk-width-1-3@m">
              <h1
                className="uk-margin-small-top uk-text-global-family uk-h2 uk-text-bold"
                dangerouslySetInnerHTML={{ __html: content.feature.title }}
              ></h1>
            </div>
          </div>
        </div>

        <div className="uk-section uk-section-default uk-padding-remove-bottom uk-section-small">
          <div className="uk-container">
            <div className="uk-width-3-5 uk-width-2-5@s uk-width-4-5@m uk-width-3-5@l uk-margin-auto uk-text-center">
              <h2 className="uk-text-global-family uk-h3">
                We understand how you live
              </h2>
              <div className="uk-grid uk-grid-large uk-grid-match uk-margin-medium-top">
                {content.benefits.map((benefit) => (
                  <div key={benefit.icon.type} className="uk-width-1-3@m">
                    <span
                      uk-icon={
                        "icon: " +
                        benefit.icon.type +
                        "; ratio: " +
                        benefit.icon.ratio +
                        ";"
                      }
                      className="uk-text-primary uk-margin-bottom"
                    ></span>
                    <p className="uk-text-primary uk-margin-remove-top uk-margin-large-bottom">
                      {benefit.message}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Split Panels */}
        <div className="uk-section uk-section-small uk-padding-remove-bottom">
          <div className="uk-container">
            <SplitCard
              title={content.story.title}
              desc={content.story.desc}
              image={content.story.image.url}
              margin={true}
              align="left"
            />

            <SplitCard
              title={content.comfort.title}
              desc={content.comfort.desc}
              image={content.comfort.image.url}
              margin={true}
              align="right"
            />
          </div>
        </div>

        {/* Testimonials */}
        {/* <CenterText
          title={content.testimonialsTitle}
          data={content.testimonial}
          testimonial={true}
        /> */}

        <div className="uk-section uk-padding-remove-top">
          <div className="uk-container">
            {/* <SplitCard
              title={content.reece.title}
              desc={content.reece.desc}
              image={content.reece.image.url}
              margin={true}
              align="left"
            /> */}

            <Panels data={content.panels} />
          </div>
        </div>

        <div className="uk-section uk-section-small">
          <div className="uk-container">
            <div className="uk-width-3-4 uk-width-2-3@s uk-width-1-2@m">
              <small className="uk-text-meta">{content.description} </small>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index
