import React from "react"
import { Link } from "gatsby"

class Panels extends React.Component {
  render() {
    const data = this.props.data
    const total = data.length

    if (total > 2) {
      var colClass =
        "uk-width-1-1 uk-width-1-2@m uk-width-1-3@l uk-margin-auto uk-margin-bottom panel-outer"
      var panelClass =
        "uk-background-muted uk-margin-bottom uk-border-rounded uk-height-small uk-cover-container"
    } else {
      var colClass =
        "uk-width-1-1 uk-width-1-2@m uk-margin-auto uk-margin-bottom panel-outer"
      var panelClass =
        "uk-background-muted uk-margin-bottom uk-border-rounded uk-height-medium uk-cover-container"
    }

    return (
      <div
        className="uk-grid uk-grid-small uk-flex uk-child-width-expand uk-margin-large-bottom"
        uk-grid=""
      >
        {data.map((panel, i) => (
          <div key={i} className={colClass}>
            <Link to={panel.link.url}>
              {!!panel.image ? (
                <div className={panelClass}>
                  <img
                    src={panel.image.url}
                    alt={panel.image.title}
                    uk-cover=""
                  />
                </div>
              ) : null}
              <div className="uk-flex uk-flex-top uk-flex-middle uk-flex-around uk-padding-small uk-padding-remove-vertical panel-outer__text">
                <h4 className="uk-margin-remove uk-flex uk-flex-middle uk-text-bold uk-width-2-3">
                  {!!panel.icon ? (
                    <span
                      className="uk-margin-small-right"
                      uk-icon={
                        "icon: " +
                        panel.icon.type +
                        "; ratio: " +
                        panel.icon.ratio +
                        ";"
                      }
                    ></span>
                  ) : null}
                  {panel.title}
                </h4>
                {!!panel.link.url ? (
                  <div className="uk-flex-none">
                    <div className="uk-button uk-button-primary uk-visible@s uk-border-pill">
                      {!!panel.link.text ? panel.link.text : "Learn more"}
                    </div>
                    <div className="uk-hidden@s">
                      <span
                        className="uk-margin-small-right"
                        uk-icon="icon: chevron-right; ratio: 1.5;"
                      ></span>
                    </div>
                  </div>
                ) : null}
              </div>
            </Link>
          </div>
        ))}
      </div>
    )
  }
}

export default Panels
