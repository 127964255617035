import React from "react"
import { Link } from "gatsby"

class CenterText extends React.Component {
  render() {
    const title = this.props.title
    const data = this.props.data
    const testimonial = this.props.testimonial

    return (
      <div className="uk-container">
        <div className="uk-width-3-5@l uk-margin-auto uk-text-center">
          <h3>{title}</h3>
          <p className="uk-margin-medium-top">
            {testimonial ? "“" : ""}
            {data.desc}
            {testimonial ? "”" : ""}
          </p>
          <div className="uk-margin-medium-top">
            <p className="uk-margin-remove">{data.name}</p>
            <Link to={data.link} className="uk-margin-small uk-link-dotted">
              {data.linkText}
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default CenterText
